import {
  Alert,
  Collapse,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  IconButton,
  Checkbox,
  FormControlLabel,
  Link,
} from '@mui/material';
import { Appbar, GradientButton } from '../../components';
import CloseIcon from '@mui/icons-material/Close';
import { CSSProperties, useState } from 'react';
import { handleValidate } from '../../../backend/utils/HandleValidate';
import { RootState } from '../../../backend/redux/store';
import { useSelector } from 'react-redux';
import { OnboardingClientInfo } from '../../../backend/models';
import { useNavigate } from 'react-router-dom';
import { CustomCardContainer, CustomContainer, theme } from './styles';
import { ThemeProvider } from '@mui/material/styles';
import { getAuth } from 'firebase/auth';

export const ClientValidationPage = () => {
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [month, setMonth] = useState<string>('mm');
  const [day, setDay] = useState<string>('dd');
  const [year, setYear] = useState<string>('yyyy');
  const [residenceState, setResidenceState] = useState<string>('');
  const [gender, setGender] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [firstNameErr, setFirstNameErr] = useState<boolean>();
  const [lastNameErr, setLastNameErr] = useState<boolean>();
  const [monthErr, setMonthErr] = useState<boolean>();
  const [dayErr, setDayErr] = useState<boolean>();
  const [yearErr, setYearErr] = useState<boolean>();
  const [stateErr, setStateErr] = useState<boolean>();
  const [gendErr, setGendErr] = useState<boolean>();
  const [phoneErr, setPhoneErr] = useState<boolean>();

  const uid = useSelector((state: RootState) => state.user.uid);
  const navigate = useNavigate();

  const days = Array.from({ length: 31 }, (_, i) => i + 1);
  const months = Array.from({ length: 12 }, (_, i) => i + 1);
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: currentYear - 1900 + 1 }, (_, i) => currentYear - i);

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTermsAccepted(e.target.checked);
  };

  const handleContinue = () => {
    if (!termsAccepted) {
      setErrorMessage('Please agree to the terms to proceed.');
      setShowAlert(true);
    } else {
      const formattedDay = day.toString().padStart(2, '0');
      const formattedMonth = month.toString().padStart(2, '0');
      const date = `${formattedMonth}/${formattedDay}/${year}`;

      const auth = getAuth();
      const userEmail = auth.currentUser?.email;
      if (!userEmail) {
        setErrorMessage('Unable to retrieve your email. Please try again.');
        setShowAlert(true);
        return;
      }
      const data: OnboardingClientInfo = {
        clientID: uid?.toString(),
        email: userEmail,
        firstName: firstName,
        lastName: lastName,
        dob: date,
        stateOfResidence: residenceState,
        gender: gender,
        rating: 5,
        phoneNumber: phoneNumber,
        profilePhoto: '',
      };
      console.log(data);
      handleValidate(data, navigate, (message: string) => {
        setErrorMessage(message);
        setShowAlert(true);
      });
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <div>
        {/* AppBar */}
        <Appbar showMenu={true} />

        <CustomContainer>
          <CustomCardContainer>
            <Typography
              variant='h6'
              component='div'
              sx={{ marginTop: 5, marginBottom: 4, color: '#F27C22', fontWeight: 'bold' }}
            >
              We Need Some Final Information...
            </Typography>

            <TextField
              error={firstNameErr}
              label='First Name'
              variant='outlined'
              fullWidth
              sx={{
                marginBottom: 2,
                '& .MuiOutlinedInput-root': {
                  borderRadius: '15px',
                  height: '61px',
                  '& .MuiOutlinedInput-notchedOutline': { border: '3px solid #000' },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': { border: '3px solid #F27C22' },
                },
              }}
              InputLabelProps={{ style: { color: '#000' } }}
              onChange={(e) => setFirstName(e.target.value)}
            />

            <TextField
              error={lastNameErr}
              label='Last Name'
              variant='outlined'
              fullWidth
              sx={{
                marginBottom: 2,
                '& .MuiOutlinedInput-root': {
                  borderRadius: '15px',
                  height: '61px',
                  '& .MuiOutlinedInput-notchedOutline': { border: '3px solid #000' },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': { border: '3px solid #F27C22' },
                },
              }}
              InputLabelProps={{ style: { color: '#000' } }}
              onChange={(e) => setLastName(e.target.value)}
            />

            <TextField
              error={stateErr}
              label='State of Residence'
              variant='outlined'
              fullWidth
              sx={{
                marginBottom: 2,
                '& .MuiOutlinedInput-root': {
                  borderRadius: '15px',
                  height: '61px',
                  '& .MuiOutlinedInput-notchedOutline': { border: '3px solid #000' },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': { border: '3px solid #F27C22' },
                },
              }}
              InputLabelProps={{ style: { color: '#000' } }}
              onChange={(e) => setResidenceState(e.target.value)}
            />

            <TextField
              error={phoneErr}
              label='Phone Number (For non-US numbers, please include country code without spaces)'
              variant='outlined'
              fullWidth
              sx={{
                marginBottom: 2,
                '& .MuiOutlinedInput-root': {
                  borderRadius: '15px',
                  height: '61px',
                  '& .MuiOutlinedInput-notchedOutline': { border: '3px solid #000' },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': { border: '3px solid #F27C22' },
                },
              }}
              InputLabelProps={{ style: { color: '#000' } }}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />

            <FormControl fullWidth sx={{ marginBottom: 2 }}>
              <InputLabel id='gender-label' sx={{ color: '#000' }}>
                Gender
              </InputLabel>
              <Select
                labelId='gender-label'
                value={gender}
                sx={{
                  borderRadius: '15px',
                  height: '61px',
                  '& .MuiOutlinedInput-notchedOutline': { border: '3px solid #000' },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': { border: '3px solid #F27C22' },
                }}
                onChange={(e) => setGender(e.target.value)}
                label='Gender'
                error={gendErr}
              >
                <MenuItem value=''>
                  <em></em>
                </MenuItem>
                <MenuItem value='Male'>Male</MenuItem>
                <MenuItem value='Female'>Female</MenuItem>
                <MenuItem value='Non-binary'>Non-binary</MenuItem>
                <MenuItem value='Other'>Other</MenuItem>
              </Select>
            </FormControl>

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '100%',
                marginBottom: '2rem',
              }}
            >
              <FormControl sx={{ marginRight: 1, flex: 1 }}>
                <InputLabel id='month-label' sx={{ color: '#000', transform: 'translate(17px, -10px) scale(0.75)' }}>
                  MM
                </InputLabel>
                <Select
                  labelId='month-label'
                  value={month}
                  sx={{
                    borderRadius: '15px',
                    height: '61px',
                    '& .MuiOutlinedInput-notchedOutline': { border: '3px solid #000' },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': { border: '3px solid #F27C22' },
                    paddingLeft: 1,
                    paddingBottom: 0.5,
                  }}
                  onChange={(e) => setMonth(e.target.value)}
                  label='MM'
                  error={monthErr}
                >
                  <MenuItem value='mm'>MM</MenuItem>
                  {months.map((month) => (
                    <MenuItem key={month} value={month}>
                      {month.toString().padStart(2, '0')}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl sx={{ marginRight: 1, flex: 1 }}>
                <InputLabel id='day-label' sx={{ color: '#000', transform: 'translate(17px, -10px) scale(0.75)' }}>
                  DD
                </InputLabel>
                <Select
                  labelId='day-label'
                  value={day}
                  sx={{
                    borderRadius: '15px',
                    height: '61px',
                    '& .MuiOutlinedInput-notchedOutline': { border: '3px solid #000' },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': { border: '3px solid #F27C22' },
                    paddingLeft: 1,
                    paddingBottom: 0.5,
                  }}
                  onChange={(e) => setDay(e.target.value)}
                  label='DD'
                  error={dayErr}
                >
                  <MenuItem value='dd'>DD</MenuItem>
                  {days.map((day) => (
                    <MenuItem key={day} value={day}>
                      {day.toString().padStart(2, '0')}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl sx={{ flex: 1 }}>
                <InputLabel id='year-label' sx={{ color: '#000', transform: 'translate(17px, -10px) scale(0.75)' }}>
                  YYYY
                </InputLabel>
                <Select
                  labelId='year-label'
                  value={year}
                  sx={{
                    borderRadius: '15px',
                    height: '61px',
                    '& .MuiOutlinedInput-notchedOutline': { border: '3px solid #000' },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': { border: '3px solid #F27C22' },
                    paddingLeft: 1,
                    paddingBottom: 0.5,
                  }}
                  onChange={(e) => setYear(e.target.value)}
                  label='YYYY'
                  error={yearErr}
                >
                  <MenuItem value='yyyy'>YYYY</MenuItem>
                  {years.map((year) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <FormControlLabel
              control={
                <Checkbox
                  checked={termsAccepted}
                  name='termsAccepted'
                  onChange={handleCheckboxChange}
                  sx={{
                    color: '#000',
                    '&.Mui-checked': {
                      color: '#000',
                    },
                    '& .MuiSvgIcon-root': {
                      fontSize: 26,
                    },
                    ml: 1,
                  }}
                />
              }
              label={
                <span>
                  I agree with all the{' '}
                  <Link
                    href='https://gyfrapp.com/policies/terms-of-service'
                    target='_blank'
                    rel='noopener noreferrer'
                    sx={{ color: '#F27C22' }}
                  >
                    terms of services
                  </Link>{' '}
                  and{' '}
                  <Link
                    href='https://gyfrapp.com/policies/privacy-policy'
                    target='_blank'
                    rel='noopener noreferrer'
                    sx={{ color: '#F27C22' }}
                  >
                    privacy policies
                  </Link>{' '}
                  of Gyfr.
                </span>
              }
            />

            <Collapse in={showAlert}>
              <Alert
                severity='error'
                action={
                  <IconButton aria-label='close' color='inherit' size='small' onClick={() => setShowAlert(false)}>
                    <CloseIcon fontSize='inherit' />
                  </IconButton>
                }
                sx={{ mt: '1rem' }}
              >
                {errorMessage}
              </Alert>
            </Collapse>

            <GradientButton
              showArrowIcon
              text="I'm ready to train!"
              handleClick={handleContinue}
              styles={{ marginTop: '2rem' }}
            />
          </CustomCardContainer>
        </CustomContainer>
      </div>
    </ThemeProvider>
  );
};
